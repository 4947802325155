import LazyLoad from 'vanilla-lazyload'

const lazy = {
    init() {
        var myLazyLoad = new LazyLoad({
            elements_selector: ".js-lazy",
            threshold: -50,
            class_loaded: 'is-loaded',
            callback_loaded: function(el) {
              el.parentNode.classList.add('is-loaded');
            }
        });
    }
};

export default lazy