
const animations = {
    elements: {
        fadeIn: '.js-fade-in',
    },
    observer: null,
    init() {
        const self = this;
        
        self.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                entry.target.classList.toggle('is-visible', entry.isIntersecting);

                // If element is visible, stop observing
                if (entry.isIntersecting) {
                    self.observer.unobserve(entry.target);
                }
            });
        }, {
            // threshold: 0.5, // 50% of element must be visible to trigger animation

        });

        const $fadeInElements = document.querySelectorAll(self.elements.fadeIn);

        if ($fadeInElements.length) {
            $fadeInElements.forEach(el => {
                self.observer.observe(el);
            });
        }

    }
};

export default animations